import React from 'react'
import PropTypes from 'prop-types'
import LandingComponent from 'libs/components/landing/illustrated'
import LogoPNG from 'images/cyan-logo.png'
import Features from './components/features'
import CTA from './components/cta'
import Footer from './components/footer'
import { useHistory } from 'react-router-dom'
import Text from 'libs/components/text'
import Button from 'libs/components/button'


function Landing(props) {

    const history = useHistory()

    const toLogin = () => {
        window.location.href = 'https://apaloans.lendbox.io'
    }

    const onHeaderItemClicked = (index) => {
        switch (index) {
            case 0:
                window.location.href = '#about'
                break
            case 1:
                window.location.href = 'mailto:apaloanz@gmail.com'
                break
            default:
                break
        }
    }

    return (
        <div className='flex flex-col'>


            <LandingComponent
                onLoginClicked={() => toLogin()}
                headerItems={['About Us', 'Contact']}
                onHeaderItemClicked={onHeaderItemClicked}
                heading={
                    {
                        blackText: "Hassle-Free",
                        colorText: "Quick loans"
                    }
                }
                logo={
                    <a href="#">
                        <div className={'h-16 w-16 p-2'}>
                            <img src={LogoPNG} alt='Apaloans logo' />
                        </div >
                    </a>}

                notification={{
                    text: 'from 15%',
                    subText: 'Armotized Interest',
                    onClick: () => console.log('')
                }
                }

                subHeading='Get a quick loan within 10 minutes.'
                sideContent={
                    <div className='p-4 flex items-center justify-center flex-col space-y-6 text-gray-400 font-bold'>
                        <p>1 Week = 15%</p>
                        <p>2 Weeks = 20%</p>
                        <p>3 Weeks = 24%</p>
                        <p>1 month or more = 28% Armotized</p>

                        <div>
                            <Button
                                tailwind='px-16 my-4'
                                variant='secondary'
                                onClick={() => toLogin()}>Request Loan</Button>
                        </div>
                    </div>}
            />


            <svg className="absolute hidden lg:block transform -translate-x-2/4 translate-y-3/4 z-0" width="784" height="404" fill="none" viewBox="0 0 784 404">
                <defs>
                    <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-gray-800" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>



            <Features />

            <div id='about'>
                <CTA
                    onRequestLoan={() => toLogin()} />
            </div>

            <div className=''>
                <Footer />
            </div>
        </div>
    )
}

Landing.propTypes = {

}

export default Landing

