import React from 'react';
import Landing from './app/landing';
import { Route, Switch } from 'react-router-dom';
import PrivacyPolicy from 'app/legal/privacy-policy';
import Tos from 'app/legal/tos';



function App() {


  return (
    <Switch>
      <Route path='/tos'><Tos /></Route>
      <Route path='/privacy-policy'><PrivacyPolicy /></Route>
      <Route path='/'> <Landing /> </Route>
    </Switch>
  );
}

export default App;
