import React from 'react'
import PropTypes from 'prop-types'
import CtaPNG from 'images/cta-bg.webp'

function CTA() {

  return (
    <div class="relative bg-gray-900">
      <div class="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img class="w-full h-full object-cover" src={CtaPNG} alt="" />
        <div aria-hidden="true" class="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600" style={{ "mix-blend-mode": "multiply" }}></div>
      </div>
      <div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <h2 class="text-base font-semibold uppercase tracking-wider text-gray-300">
            Digital Lending
          </h2>
          <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            We’re here to help
          </p>
          <p class="mt-3 text-lg text-gray-300">
            ApaLoanz offers instant hassle-free loans powered by technology. We are a Fintech company offering digital lending solutions by utilizing technology.
            We leverage on technologies like mobile money wallets and EFTPOS as well as many other technological advances to bring innovative financial solutions and improve lives of people.
          </p>
          <div class="mt-8">
            <div class="inline-flex rounded-md shadow">
              <a href="https://apaloans.lendbox.io" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                Request Loan

                <svg class="-mr-1 ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA.propTypes = {

}

export default CTA

