import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from 'libs/components/theme'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from 'libs/auth-react/components/auth-provider';





ReactDOM.render(

  <React.StrictMode>
    <AuthProvider
      port={4001}
      productionServerUrl='https://server.apaloans.co.zm/graphql'

    >
      <ThemeProvider theme={theme}>

        <BrowserRouter>

          <App />

        </BrowserRouter>


      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
  ,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
