import React from 'react';
import Text from 'libs/components/text'

const Tos = () => {
    const headingType = 'heading-small'
    const contentType = 'text'
    const contentTailwind = ''
    const headingTailWind = ''

    const spanTailwind = 'text-gray-900 font-medium'

    const appName = 'Apaloans'
    
    return (
        <div>
            <div className='w-full py-6 px-8 border-b border-gray-300 mb-6'>
                <Text type='heading'> Terms of Service</Text>
            </div>
            <div className='p-8'>
                <Text type={contentType} tailwind={contentTailwind}>
                    The following terms and conditions (“Terms”) govern all use of the {appName} website and all content, services, and products available at or through the website (taken together, our “Services”). Our Services are offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, {appName}'s Privacy Policy) and procedures that may be published from time to time by {appName} (collectively, the “Agreement”). You agree that we may automatically upgrade our Services, and these Terms will apply to any upgrades. Your agreement is with {appName} ("{appName}" or “we”).

                    Please read this Agreement carefully before accessing or using our Services. By accessing or using any part of our Services, you agree to become bound by the Terms of this Agreement. If you do not agree to all the Terms of this Agreement, then you may not access or use any of our Services. If these Terms are considered an offer by {appName}, acceptance is expressly limited to these Terms.

                    Our Services are not directed to children younger than 13, and access and use of our Services is only offered to users 13 years of age or older. If you are under 13 years old, please do not register to use our Services. Any person who registers as a user or provides their personal information to our Services represents that they are 13 years of age or older.

                    Use of our Services requires a {appName} account. You agree to provide us with complete and accurate information when you register for an account. You will be solely responsible and liable for any activity that occurs under your username. You are responsible for keeping your password secure.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>1. {appName}.com</Text>
                <Text type={contentType} tailwind={contentTailwind}>
                    <span className={spanTailwind}> Your {appName} Account and {appName} application.</span> If you create a feedback application on {appName}, you are responsible for maintaining the security of your account and application, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the application. You must immediately notify {appName} of any unauthorized uses of your application, your account, or any other breaches of security. {appName} will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.
                <br/><span className={spanTailwind}> Responsibility of Contributors.</span> If you operate an application, create a post on an application, comment on an application, post material to {appName}, post links on {appName}, or otherwise make (or allow any third party to make) material available (any such material, “Content”), you are entirely responsible for the content of, and any harm resulting from, that Content or your conduct. That is the case regardless of what form the Content takes, which includes, but is not limited to text, photo, video, audio, or code. By using {appName}, you represent and warrant that your Content and conduct do not violate these Terms. By submitting Content to our Service for inclusion on your application, you grant {appName} a world-wide, royalty-free, and non-exclusive license to reproduce, modify, adapt and publish the Content solely for the purpose of displaying, distributing, and promoting your application. If you delete Content, {appName} will use reasonable efforts to remove it from {appName}, but you acknowledge that caching or references to the Content may not be made immediately unavailable. Without limiting any of those representations or warranties, {appName} has the right (though not the obligation) to, in {appName}’s sole discretion, (i) reclaim your applications’s URL due to prolonged inactivity, (ii) refuse or remove any content that, in {appName}’s reasonable opinion, violates any {appName} policy or is in any way harmful or objectionable, or (iii) terminate or deny access to and use of {appName} to any individual or entity for any reason. {appName} will have no obligation to provide a refund of any amounts previously paid.
                <br/><span className={spanTailwind}>Attribution. </span>{appName} reserves the right to display attribution text or links in your site footer or sidebar, attributing {appName}, for example. The sidebar may not be altered or removed.
                <br/><span className={spanTailwind}> Payment and Renewal.</span>
                <br/> <span className={spanTailwind}> General Terms.</span> Optional paid services such as extra features are available (any such services, an “Upgrade”). By selecting an Upgrade you agree to pay {appName} the monthly or annual subscription fees indicated for that service via our online reseller Paddle.com. Paddle is the Merchant of Record for all our orders. Paddle provides all customer service inquiries and handles returns. Payments will be charged on a pre-pay basis on the day you sign up for an Upgrade and will cover the use of that service for a monthly or annual subscription period as indicated.
                <br/> <span className={spanTailwind}>Automatic Renewal.</span> Unless you notify {appName} before the end of the applicable subscription period that you want to cancel an Upgrade, your Upgrade subscription will automatically renew and you authorize us to collect the then-applicable annual or monthly subscription fee for such Upgrade (as well as any taxes) using any credit card or other payment mechanism we have on record for you. Upgrades can be canceled at any time in the Upgrades section of your boards’s dashboard.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>2. Responsibility of Visitors.</Text>
                <Text type={contentType} tailwind={contentTailwind}> Rebase has not reviewed, and cannot review, all of the material, including computer software, posted to our Services, and cannot therefore be responsible for that material’s content, use or effects. By operating our Services, {appName} does not represent or imply that it endorses the material there posted, or that it believes such material to be accurate, useful, or non-harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. Our Services may contain content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. Our Services may also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights, of third parties, or the downloading, copying or use of which is subject to additional terms and conditions, stated or unstated. {appName} disclaims any responsibility for any harm resulting from the use by visitors of our Services, or from any downloading by those visitors of content there posted.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>3. Content Posted on Other Websites.</Text>
                <Text type={contentType} tailwind={contentTailwind}> We have not reviewed, and cannot review, all of the material, including computer software, made available through the websites and webpages to which {appName} links, and that link to {appName}. {appName} does not have any control over those non-{appName} websites, and is not responsible for their contents or their use. By linking to a non-{appName} website, {appName} does not represent or imply that it endorses such website. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. {appName} disclaims any responsibility for any harm resulting from your use of non-{appName} websites and webpages.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>4. Copyright Infringement and DMCA Policy.</Text>
                <Text type={contentType} tailwind={contentTailwind}> As {appName} asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located
                on or linked to by {appName} violates your copyright, you are encouraged to notify {appName} in accordance with {appName}’s Digital Millennium Copyright Act (“DMCA”) Policy. {appName} will respond to all such notices,
                including as required or appropriate by removing the infringing material or disabling all links to the infringing material. {appName} will terminate a visitor’s access to and use of the website if, under appropriate
                circumstances, the visitor is determined to be a repeat infringer of the copyrights or other intellectual property rights of {appName} or others. In the case of such termination, {appName} will have no obligation
                to provide a refund of any amounts previously paid to {appName}.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}> 5. Intellectual Property.</Text>
                <Text type={contentType} tailwind={contentTailwind}>This Agreement does not transfer from {appName} to you any {appName} or third party intellectual property, and all right, title, and interest in and to such property
                will remain (as between the parties) solely with {appName}. {appName}, the {appName} logo, and all other trademarks, service marks, graphics and logos used in connection with {appName} or our Services,
                are trademarks or registered trademarks of {appName} or {appName}’s licensors. Other trademarks, service marks, graphics and logos used in connection with our Services may be the trademarks of other third parties.
                Your use of our Services grants you no right or license to reproduce or otherwise use any {appName} or third-party trademarks.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}> 6. Changes.</Text>
                <Text type={contentType} tailwind={contentTailwind}>We are constantly updating our Services, and that means sometimes we have to change the legal terms under which our Services are offered. We reserves the right to
                update and change these Terms at any time without notice. If the revision, in our sole discretion, is material we will notify you via e-mail to the email associated with your account. If you disagree with our changes,
                then you should stop using our Services. Your continued use of our Services will be subject to the new terms. However, any dispute that arose before the changes shall be governed by the Terms
                (including the binding individual arbitration clause) that were in place when the dispute arose.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>7. Termination.</Text>
                <Text type={contentType} tailwind={contentTailwind}>{appName} may terminate your access to all or any part of our Services at any time, with or without cause, with or without notice, effective immediately.
                If you wish to terminate this Agreement or your {appName} account (if you have one), you may simply discontinue using our Services. All provisions of this Agreement which by their nature should survive termination
                shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>8. Disclaimer of Warranties.</Text>
                <Text type={contentType} tailwind={contentTailwind}> Our Services are provided “as is.” {appName} and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including,
                without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither {appName} nor its suppliers and licensors, makes any warranty that our Services will be error
                free or that access thereto will be continuous or uninterrupted. If you’re actually reading this, here’s a treat. You understand that you download from, or otherwise obtain content or services through,
                our Services at your own discretion and risk.
                </Text>

                <Text type={headingType} tailwind={headingTailWind}>10. Limitation of Liability.</Text>
                <Text type={contentType} tailwind={contentTailwind}> In no event will {appName}, or its suppliers or licensors, be liable with respect to any subject matter of this Agreement under any contract, negligence,
                strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or
                corruption of data; or (iv) for any amounts that exceed the fees paid by you to {appName} under this Agreement during the twelve (12) month period prior to the cause of action. {appName} shall have no liability for
                any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>11. General Representation and Warranty.</Text>
                <Text type={contentType} tailwind={contentTailwind}> You represent and warrant that (i) your use of our Services will be in strict accordance with the {appName} Privacy Policy, with this Agreement, and with all
                applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content,
                and including all applicable laws regarding the transmission of technical data exported from Switzerland or the country in which you reside) and (ii) your use of our Services will not infringe or misappropriate
                the intellectual property rights of any third party.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>12. Indemnification.</Text>
                <Text type={contentType} tailwind={contentTailwind}> You agree to indemnify and hold harmless {appName}, its contractors, and its licensors, and their respective directors, officers, employees, and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of our Services, including but not limited to your violation of this Agreement.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>13. Miscellaneous</Text>
                <Text type={contentType} tailwind={contentTailwind}>This Agreement constitutes the entire agreement between {appName} and you concerning the subject matter hereof, and they may only be modified by a written amendment
                signed by an authorized executive of {appName}, or by the posting by {appName} of a revised version.

                If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect.
                A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.

                You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; {appName} may assign its rights under this Agreement without condition.
                This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.
                </Text>
                <Text type={headingType} tailwind={headingTailWind}>14. Attribution</Text>
                <Text type={contentType} tailwind={contentTailwind}> These terms of service are based on those of WordPress.com which have been made available under a Creative Commons ShareAlike license.
                </Text>
            </div>
        </div>

    );
};

export default Tos;